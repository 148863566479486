// „Import“ jQuery from WebPack ProvidePlugin
window.$ = jQueryPlugin;
window.jQuery = jQueryPlugin;

// Import jQuery plugins
require('jquery-ui');
require('selectize');
require('./Vendors/jquery-file-uploader/jquery-file-upload.js');
require('jquery-touchswipe');
require('./Vendors/lightbox/lightbox.min');


require('./../Vendor/jquery.mmenu-5.6.4/js/jquery.mmenu.oncanvas');
require('./../Vendor/jquery.mmenu-5.6.4/addons/offcanvas/jquery.mmenu.offcanvas');
require('./../Vendor/jquery.mmenu-5.6.4/addons/scrollbugfix/jquery.mmenu.scrollbugfix');
require('./../Vendor/jquery.mmenu-5.6.4/addons/navbars/jquery.mmenu.navbars');
